import { useCallback } from 'react';
import './timePicker.module.scss'
import moment from 'moment-timezone';

export const TimePicker = ({ onChange, date, value, setTimezone}) => {
  const timezone = setTimezone || moment.tz.guess();


  const setDefaultTime = useCallback(() => {
    const formattedTime = moment.tz(timezone).format('HH:mm');
    onChange(formattedTime);
  }, [onChange, timezone]);

  const handleTimeChange = (event) => {
    const selectedTime = event.target.value;
    onChange(selectedTime);
  };

  const clearTime = () => {
    onChange('');
  };

  return (
    <>
      <div className="time-picker-container tw-ml-2 tw-relative tw-border input-group-text tw-bg-transparent tw-rounded-lg"
           style={{maxWidth: '91px'}}>
        <input
          type="time"
          value={value}
          className={!date ? 'tw-cursor-not-allowed' : ''}
          onChange={handleTimeChange}
          disabled={!date}
          onClick={setDefaultTime}
        />
        {value && (
          <button onClick={clearTime} className="btn btn-sm tw-ml-1 tw-absolute tw-right-0 tw-pb-1">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.2248 13.8219L0.174805 12.7719L5.9498 6.99688L0.174805 1.22188L1.2248 0.171875L6.9998 5.94688L12.7748 0.171875L13.8248 1.22188L8.0498 6.99688L13.8248 12.7719L12.7748 13.8219L6.9998 8.04688L1.2248 13.8219Z"
                fill="#7F7F7F" />
            </svg>
          </button>
        )}
      </div>
    </>
  )
}