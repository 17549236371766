import Button from '../../../../components/button';
import { Dialog } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';

export const ModalFileError = ({ errorMessage}) => {
  const dialog = useDialog();
  const handleCloseDialog = () => {
    dialog.close();
  }

  return(
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
          maxWidth: '436px',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-items-end tw-p-6 tw-bg-white">
        <h4 className="tw-text-[#191919] tw-leading-5 tw-text-base tw-font-semibold tw-mb-14">{errorMessage}</h4>
        <Button
          className="tw-w-fit tw-rounded-lg tw-font-medium tw-text-sm"
          onClick={handleCloseDialog}
        >Понятно</Button>
      </div>
    </Dialog>
  )
}