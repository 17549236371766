export const validateDimensionsForm = (values) => {
  const errors = {};

  if (!values.correct_length) {
    errors.correct_length = "";
  } else if (values.correct_length <= 0 || values.correct_length >= 100_000) {
    errors.correct_length = "";
  }

  if (!values.correct_height) {
    errors.correct_height = "";
  } else if (values.correct_height <= 0 || values.correct_height >= 100_000) {
    errors.correct_height = "";
  }

  if (!values.correct_width) {
    errors.correct_width = "";
  } else if (values.correct_width <= 0 || values.correct_width >= 100_000) {
    errors.correct_width = "";
  }

  if (!values.correct_weight) {
    errors.correct_weight = "";
  } else if (values.correct_weight <= 0 || values.correct_weight >= 5_000_000) {
    errors.correct_weight = "";
  }

  return errors;
};