import { Dialog, DialogActions } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { Field, Form } from 'react-final-form';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import classes from '../products.module.sass';
import { createErrorNotification, createNotification } from '../../../../utils/notifications';
import { SelectMUI } from '../../../../components/selectMUI';
import { useSendUserDataMutation } from '../../../../services/adminAPIs';
import { generatePassword } from '../../../../utils/common';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { OrganizationsAutocomplete } from './components/OrganizationsAutocomplete';
import { useSelector } from 'react-redux';

const roles = {
  Админ: 'admin',
  Юзер: 'user',
  Суперадмин: 'super_admin',
};

const accessesRoles = {
  admin: ['Админ', 'Юзер'],
  super_admin: ['Админ', 'Юзер', 'Суперадмин'],
};

export const AddUser = ({ title, onSubmit, value }) => {
  const dialog = useDialog();
  const [formValue, setFormValue] = useState(value);
  const currentUser = useSelector((state) => state.auth.user);
  const [valuesToSend, setValuesToSend] = useState({
    email: '',
    password: '',
  });
  const [step, setStep] = useState(1);

  const [sendUserData] = useSendUserDataMutation();

  useEffect(() => {
    if (formValue?.id !== value?.id) {
      setFormValue(value);
    }
  }, [value, formValue?.id]);

  const handleShowConfirmWindow = useCallback(
    (payload) => {
      if (value?.id && !payload.password) {
        dialog.close();
      } else {
        setStep(2);
      }
    },
    [dialog, value?.id]
  );

  const handleFormSubmit = useCallback(
    async (payload, form) => {
      const { id, full_name, email, password, role, organization_ids } = payload;
      const initialValues = form.getState().initialValues;

      const changedValues = {
        ...(id ? { id } : null),
      };

      if (full_name !== initialValues.full_name) changedValues.full_name = full_name;
      if (email !== initialValues.email) changedValues.email = email;
      if (password && password !== initialValues.password) changedValues.password = password;
      if (role !== initialValues.role) changedValues.role = roles[role] || 'user';

      setValuesToSend({
        email,
        password,
      });

      const body = {
        full_name,
        email,
        password,
        role: roles[role] || 'user',
        ...(!id && { organization_ids: organization_ids?.map((organization) => organization?.id) }),
      };

      try {
        onSubmit && (await onSubmit(id ? changedValues : body));
        handleShowConfirmWindow(payload);
      } catch (err) {
        console.error(err);
      }
    },

    [handleShowConfirmWindow, onSubmit]
  );

  const handleSendUserData = useCallback(async () => {
    await sendUserData(valuesToSend)
      .unwrap()
      .then(() => {
        createNotification('Данные отправлены');
        setStep(1);
        dialog.close();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при отправлении данных');
      });
  }, [sendUserData, valuesToSend, dialog]);

  const validate = (values) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const MIN_PASSWORD_LENGTH = 10;
    const spacesRegex = /\s/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;

    const shouldCheckPassword = !value?.id || values?.password;

    const errors = {};
    if ((!values?.full_name || values?.full_name.length < 0) && shouldCheckPassword) {
      errors.full_name = 'Обязательно для заполнения';
    }
    if (values?.password?.length < MIN_PASSWORD_LENGTH && shouldCheckPassword) {
      errors.password = `Пароль должен содержать более ${MIN_PASSWORD_LENGTH} символов`;
    }

    if (spacesRegex.test(values?.password)) {
      errors.password = 'Пароль не должен содержать пробелы';
    }

    if (!uppercaseRegex.test(values?.password) && shouldCheckPassword) {
      errors.password = 'Пароль должен содержать хотя бы одну заглавную букву';
    }

    if (!lowercaseRegex.test(values?.password) && shouldCheckPassword) {
      errors.password = 'Пароль должен содержать хотя бы одну строчную букву';
    }

    if (!digitRegex.test(values?.password) && shouldCheckPassword) {
      errors.password = 'Пароль должен содержать хотя бы одну цифру';
    }
    if (values?.email !== '' && !emailRegex.test(values?.email)) {
      errors.email = 'Введите корректный email';
    }
    return errors;
  };

  const generateRandomPassword = (form) => {
    const generatedPass = generatePassword({
      length: 10,
      uppercase: true,
      numbers: true,
      symbols: true,
    });
    form.change('password', generatedPass);
  };

  const userRole = currentUser?.role;
  let accessesOptions = accessesRoles[userRole] || [];
  const isDisabled = formValue?.role === 'Суперадмин';
  const options = isDisabled ? ['Суперадмин'] : accessesOptions;

  return (
    <Dialog open={dialog.visibility} className={classes.select}>
      <div onClick={(e) => e.stopPropagation()}>
        {step === 1 && (
          <Form
            initialValues={formValue}
            onSubmit={handleFormSubmit}
            validate={validate}
            render={({ handleSubmit, submitError, form, invalid, submitting, pristine }) => (
              <form onSubmit={handleSubmit}>
                <div className="tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-min-w-[440px]">
                  <p className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-6">{title}</p>
                  <div className="tw-flex tw-flex-col tw-gap-6">
                    <div className="tw-flex tw-flex-col tw-gap-[10px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">ФИО</p>
                      <Field name="full_name" render={({ input }) => <Input {...input} type="text" required />} />
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[10px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Email</p>
                      <Field name="email" render={({ input }) => <Input {...input} type="text" required />} />
                    </div>
                    {!formValue?.id && (
                      <div className="tw-flex tw-flex-col tw-gap-[10px]">
                        <div className="tw-flex tw-flex-row tw-items-center tw-gap-[2px]">
                          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
                            Название организации
                          </p>
                          <Tooltip
                            title={'Если организации нет в списке, вы можете создать её через вкладку "Организации"'}
                          />
                        </div>
                        <Field
                          name="organization_ids"
                          render={({ input }) => <OrganizationsAutocomplete name={'organization_ids'} />}
                        />
                      </div>
                    )}
                    <div className="tw-flex tw-flex-col tw-gap-[10px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Пароль</p>

                      <Field
                        name="password"
                        render={({ input }) => (
                          <Input
                            type="text"
                            required={!value?.id}
                            {...input}
                            inlineError
                            errorStyle="tw-bottom-0 tw-max-w-[390px] tw-text-wrap"
                            className="tw-flex tw-flex-col tw-gap-1"
                          />
                        )}
                      />

                      <Button
                        variant="text"
                        className="tw-m-0 tw-mt-2 tw-w-fit hover:tw-bg-transparent tw-p-0 tw-font-medium tw-text-base tw-leading-5"
                        onClick={() => generateRandomPassword(form)}
                      >
                        Сгенерировать пароль
                      </Button>
                    </div>
                    <div className={`tw-flex tw-flex-col tw-gap-[10px] ${classes.select}`}>
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Роль</p>
                      <Field form={form} options={options} name="role" type="text" required>
                        {(props) => (
                          <SelectMUI
                            disabled={isDisabled}
                            {...props}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-disabled': {
                                  '& fieldset': {
                                    borderColor: '#D5D5D5 !important',
                                    borderWidth: '1px !important',
                                  },
                                },
                              },
                              '& .Mui-focused': {
                                fieldset: {
                                  borderColor: '#D5D5D5 !important',
                                  borderWidth: '1px !important',
                                },
                              },
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    {/* <div className="tw-flex tw-flex-col tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Дневной лимит</p>
                    <Input form={form} value={values['max_scan_product_day']} name="max_scan_product_day" type="number" />
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Месячный лимит</p>
                    <Input form={form} value={values['max_scan_product_month']} name="max_scan_product_month" type="number" />
                  </div> */}
                  </div>
                  <DialogActions className="tw-gap-6 tw-mt-14">
                    <Button
                      className="tw-w-fit tw-p-0 tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold hover:tw-bg-transparent"
                      variant="text"
                      disabled={submitting}
                      onClick={() => dialog.close()}
                    >
                      Отменить
                    </Button>
                    <Button
                      className="tw-text-sm tw-w-fit tw-rounded-lg tw-font-semibold"
                      disabled={submitting || invalid || pristine}
                      type="submit"
                    >
                      {value?.id ? 'Сохранить' : 'Создать'}
                    </Button>
                  </DialogActions>
                  {submitError && createErrorNotification(submitError)}
                </div>
              </form>
            )}
          />
        )}
        {step === 2 && (
          <>
            <div className="tw-p-6 tw-rounded-lg">
              <p className="tw-text-[#191919] tw-font-semibold tw-mb-14 tw-text-base">{`Отправить пользователю данные аккаунта на email ${valuesToSend.email}?`}</p>
              <DialogActions className="tw-gap-[18px]">
                <Button
                  className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent"
                  variant="text"
                  onClick={() => {
                    setStep(1);
                    dialog.close();
                  }}
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-[91px] tw-rounded-lg tw-font-semibold tw-text-sm"
                  onClick={() => handleSendUserData()}
                >
                  Отправить
                </Button>
              </DialogActions>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
