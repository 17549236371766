import { useCallback, useRef, useState } from 'react';
import { useGetOrganizationsInfiniteQuery } from '../../../../../services/adminAPIs';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { useField } from 'react-final-form';
import { debounce } from 'lodash';

export const OrganizationsAutocomplete = ({ name }) => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [inputValue, setInputValue] = useState('');
  const observer = useRef();

  const { input } = useField(name);

  const { data: organizations, isFetching } = useGetOrganizationsInfiniteQuery({
    page_num: page,
    page_size: 20,
    query: query,
  });

  const handleLoadMore = useCallback(() => {
    if (!isFetching && organizations?.page_num !== organizations?.pages) {
      setPage((prev) => prev + 1);
    }
  }, [isFetching, organizations?.page_num, organizations?.pages]);

  const debouncedSetQuery = useCallback(
    debounce((value) => {
      setQuery(value);
      setPage(1);
    }, 500),
    []
  );

  const lastElRef = useCallback(
    (node) => {
      if (isFetching) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleLoadMore();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [handleLoadMore, isFetching]
  );

  const handleChangeQuery = useCallback(
    (e) => {
      if (e?.target?.value !== undefined) {
        debouncedSetQuery(e?.target.value);
        setInputValue(e?.target.value);
      }
    },
    [debouncedSetQuery]
  );

  return (
    <Autocomplete
      disablePortal
      multiple
      limitTags={2}
      onChange={(_, newValue) => input.onChange(newValue)}
      value={input.value || []}
      filterOptions={(x) => x}
      options={organizations?.data || []}
      inputValue={inputValue}
      onInputChange={handleChangeQuery}
      getOptionLabel={(option) => option?.name}
      renderInput={(params) => <TextField {...params} />}
      loading={isFetching}
      loadingText={'Поиск...'}
      sx={{
        border: 'none',
        '& .MuiInputBase-root': {
          borderRadius: '8px',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused': {
            fieldset: {
              border: '1px solid #CCC',
            },
          },
          '&:hover': {
            fieldset: {
              border: '1px solid #CCC',
            },
          },
        },
        fieldset: {
          border: '1px solid #CCC',
        },
      }}
      ListboxProps={{
        role: 'list-box',
        style: {
          maxHeight: '250px',
        },
      }}
      renderTags={(value, getTagProps) => (
        <div className="tw-flex tw-flex-row tw-flex-wrap tw-overflow-y-auto tw-max-h-[200px] tw-gap-1">
          {value.map((option, index) => (
            <Chip key={option?.id} label={option?.name} {...getTagProps({ index })} />
          ))}
        </div>
      )}
      renderOption={(props, option, _, ownerState) => {
        return (
          <li
            {...props}
            key={option?.id}
            ref={option?.id === organizations?.data[organizations?.data.length - 1].id ? lastElRef : null}
          >
            {ownerState.getOptionLabel(option)}
          </li>
        );
      }}
    />
  );
};
