import { Checkbox, Dialog, DialogActions, useMediaQuery } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { ReactComponent as CloseIcon } from '../../../../icons/closeIcon.svg';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { ReactComponent as AddIcon } from '../../../../icons/addIcon.svg';
import Button from '../../../../components/button';
import { Form } from 'react-final-form';
import { SearchInput } from '../../../../components/input/search';
import { ReactComponent as DeleteIcon } from '../../../../icons/deleteIcon.svg';
import { adminAPIs } from '../../../../services/adminAPIs';
import { createErrorNotification, createNotification } from '../../../../utils/notifications';
import { ConfirmModal } from '../../../../components/modal/ConfirmModal';
import { ReactComponent as ArrowLeft } from '../../../../icons/arrowLeftIcon.svg';
import Input from '../../../../components/input';
import { organizationsAPIs, useGetAdminOrganizationQuery } from '../../../../services';
import { ReactComponent as EditIcon } from '../../../../icons/editIcon.svg';
import { ORGANIZATION_TYPE_OPTIONS } from '../../../../utils/constant';
import arrayMutators from 'final-form-arrays';
import { RadioGroup } from '../../../../components/radio-group';

export const EditOrganization = ({ entity, onUpdate }) => {
  const dialog = useDialog();
  const mobile = useMediaQuery('(max-width: 500px)');
  const [step, setStep] = useState(1);
  const [accesses, setAccesses] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const getAccesses = useCallback(async () => {
    const { organization_accesses } = await adminAPIs.getOrganizationAccesses(entity?.id);
    setAccesses(organization_accesses);
  }, [entity]);

  const handleDeleteAccess = useCallback(
    async (id) => {
      await adminAPIs
        .deleteAccess(id)
        .then(() => {
          createNotification('Доступ удалён');
          dialog.close();
        })
        .catch((err) => {
          console.error(err);
          createErrorNotification('Ошибка удаления доступа');
        });
    },
    [dialog]
  );

  useEffect(() => {
    getAccesses();
  }, [getAccesses]);

  const showConfirmationModal = useCallback(
    (entity) => {
      dialog.open(
        <ConfirmModal
          title={`Вы уверены, что хотите удалить доступ у ${entity?.email}`}
          value={{
            id: entity.id,
          }}
          onAgree={handleDeleteAccess}
        />
      );
    },
    [dialog, handleDeleteAccess]
  );
  const handleCloseDialog = () => {
    setStep(1);
    dialog.close();
    setSelectedUsers([]);
  };

  const handleSelectUser = (checked, user) => {
    if (checked) {
      setSelectedUsers((prev) => [...prev, user]);
    } else {
      const filteredSelectedUsers = selectedUsers.filter((selectedUser) => selectedUser.id !== user.id);
      setSelectedUsers(filteredSelectedUsers);
    }
  };
  const handleAddAccesses = async () => {
    const ids = selectedUsers.map((selectedUser) => selectedUser.id);

    const body = {
      user_ids: ids,
      organization_id: entity.id,
      access: 'write',
    };
    await adminAPIs
      .createAccess(body)
      .then(() => {
        createNotification('Доступ обновлён');
        setStep(1);
        getAccesses();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Ошибка добавление доступа');
      });
  };

  const openEditOrganization = useCallback(() => {
    dialog.open(<Edit entity={entity} onUpdate={onUpdate} />);
  }, [dialog, entity, onUpdate]);
  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          width: mobile ? '100%' : '478px',
          margin: mobile ? '0px' : '32px',
          height: mobile ? '100%' : 'fit-content',
          maxHeight: mobile ? '100%' : 'calc(100% - 64px)',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-relative tw-pt-6 tw-w-full tw-overflow-hidden">
        <CloseIcon
          className=" tw-absolute tw-top-[14px] tw-right-[14px] tw-cursor-pointer"
          onClick={() => handleCloseDialog()}
        />
        <p className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-4 tw-px-6">Настройка доступа</p>
        <p className="tw-text-[#191919] tw-leading-[18px] tw-font-medium tw-text-sm tw-mb-4 tw-px-6">{entity?.name}</p>
        {step === 1 && (
          <div className="tw-flex tw-flex-col tw-items-start tw-w-full ">
            <div className="tw-px-6 tw-flex tw-flex-col tw-justify-start tw-justify-between tw-w-full  tw-mb-6">
              <Button
                variant="text"
                className="tw-w-fit tw-p-0 tw-gap-1 tw-mb-4"
                onClick={() => openEditOrganization()}
              >
                <EditIcon />
                Изменить параметры организации
              </Button>
              <Button
                variant="text"
                onClick={() => setStep(2)}
                className="tw-w-fit tw-p-0 tw-flex tw-flex-row tw-gap-1 tw-items-center hover:tw-bg-transparent"
              >
                <AddIcon />
                Добавить доступ
              </Button>
            </div>
            <div className="tw-px-6 tw-w-full tw-mb-3">
              <Form onSubmit={() => {}}>
                {() => (
                  <SearchInput
                    className="tw-w-full"
                    type="text"
                    name="search"
                    placeholder="Найти пользователя"
                    onSearchChange={(value) => setSearchQuery(value)}
                  />
                )}
              </Form>
            </div>
            <div className="tw-flex tw-flex-col tw-w-full tw-divide-y tw-divide-y-[#F7F7F7] tw-max-h-[478px] tw-overflow-y-auto tw-px-6">
              {accesses
                ?.filter((access) => access.email.includes(searchQuery))
                .map((access) => (
                  <div
                    className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full tw-pt-[18px] tw-pb-[17px]"
                    key={entity?.user_id}
                  >
                    <p className="tw-text-[#191919] tw-font-sm tw-font-medium tw-leading-[18px] tw-truncate">
                      {access?.email}
                    </p>
                    <Button
                      variant="text"
                      className="tw-w-fit tw-p-0 hover:tw-bg-transparent"
                      onClick={() => showConfirmationModal(access)}
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                ))}
            </div>
          </div>
        )}
        {step === 2 && (
          <div className={`tw-flex tw-flex-col tw-w-full tw-gap-3 ${mobile ? 'tw-h-[100vh]' : ''}`}>
            <UsersList handleSelectUser={handleSelectUser} accesses={accesses} selectedUsers={selectedUsers} />
            <DialogActions className="tw-p-0 tw-mt-auto sm:tw-mt-0">
              <div
                className="tw-flex tw-flex-row tw-w-full tw-p-6 tw-items-center tw-gap-6 tw-justify-end tw-border-t tw-border-t-[#EEE]"
                style={{ borderRadius: '8px 8px 0 0' }}
              >
                <Button
                  variant="text"
                  className="tw-flex tw-flex-row tw-items-center tw-gap-[2px] tw-w-fit tw-p-0 tw-text-sm tw-leading-[18px] tw-font-semibold tw-text-[#666666] hover:tw-bg-transparent"
                  onClick={() => {
                    setStep(1);
                    setSelectedUsers([]);
                  }}
                >
                  <ArrowLeft className="tw-rotate-180" />
                  Назад
                </Button>
                <Button
                  className="tw-w-fit tw-text-sm tw-leading-[18px] tw-font-semibold tw-px-4 tw-py-3"
                  disabled={selectedUsers.length === 0}
                  onClick={() => handleAddAccesses()}
                >
                  Добавить
                </Button>
              </div>
            </DialogActions>
          </div>
        )}
      </div>
    </Dialog>
  );
};

const UsersList = ({ handleSelectUser, accesses, selectedUsers }) => {
  const [pageNum, setPageNum] = useState(1);
  const [query, setQuery] = useState('');
  const mobile = useMediaQuery('(max-width: 500px)');

  const { loading, hasMore, users } = useUserSearch(query, pageNum);

  const filteredUsers = users.filter((user) => !accesses?.some((access) => access.user_id === user.id));

  const observer = useRef();

  const lastUserElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNum((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleChangeQuery = (value) => {
    setQuery(value);
    setPageNum(1);
  };

  return (
    <div className={`tw-flex tw-flex-col tw-overflow-y-auto tw-px-6 tw-gap-3 ${mobile ? 'tw-max-h-[74dvh]' : ''}`}>
      <Form onSubmit={() => {}}>
        {() => (
          <SearchInput
            className="tw-w-full"
            type="text"
            name="search"
            placeholder="Найти пользователя"
            onSearchChange={handleChangeQuery}
          />
        )}
      </Form>
      <div className="tw-flex tw-flex-col tw-overflow-y-auto sm:tw-max-h-[332px] sm:tw-min-h-[332px]">
        {filteredUsers?.map((user, index) => {
          if (filteredUsers.length === index + 1) {
            return (
              <div
                className="tw-flex tw-flex-row tw-items-center tw-pt-[20px] tw-pb-[19px] tw-gap-[10px]"
                key={user?.id}
                ref={lastUserElementRef}
              >
                <Checkbox
                  className="tw-p-0"
                  checked={selectedUsers.some((selectedUser) => selectedUser.id === user.id)}
                  onChange={(e, checked) => handleSelectUser(checked, user)}
                />
                <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">{user.email}</p>
              </div>
            );
          }
          return (
            <div
              className="tw-flex tw-flex-row tw-items-center  tw-pt-[20px] tw-pb-[19px] tw-gap-[10px]"
              key={user?.id}
            >
              <Checkbox
                checked={selectedUsers.some((selectedUser) => selectedUser.id === user.id)}
                className="tw-p-0"
                onChange={(e, checked) => handleSelectUser(checked, user)}
              />
              <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">{user.email}</p>
            </div>
          );
        })}
        {users?.length === 0 && <p>Нет результатов</p>}
      </div>
    </div>
  );
};

const useUserSearch = (query, pageNum) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  useEffect(() => {
    setUsers([]);
  }, [query]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await adminAPIs
        .getUsers(10, pageNum, query)
        .then((res) => {
          setUsers((prev) => [...prev, ...res.data]);
          setHasMore(res?.data?.length > 0);
          setLoading(false);
        })
        .catch((err) => console.error(err));
    };
    fetchData();
  }, [query, pageNum]);
  return { loading, hasMore, users };
};

const Edit = ({ entity, onUpdate }) => {
  const dialog = useDialog();
  const mobile = useMediaQuery('(max-width: 500px)');

  const { data: organization } = useGetAdminOrganizationQuery(entity?.id, {
    pollingInterval: 10000,
    skip: !entity?.id,
  });

  const handleCloseEdit = useCallback(
    (res) => {
      dialog.open(<EditOrganization entity={res || entity} onUpdate={onUpdate} />);
    },
    [dialog, entity, onUpdate]
  );

  const validate = (values) => {
    const errors = {};
    if (values?.inn?.length !== 10 && values?.inn?.length !== 12) {
      errors.inn = 'ИНН должно содержать 10 или 12 цифр';
    }

    if (!values?.scan_limit_max || values?.scan_limit_max <= organization?.count_scan_in_month) {
      errors.scan_limit_max = 'Значение должно быть больше использованных лимитов за текущий месяц';
    }

    return errors;
  };

  const handleEditOrganization = async (value) => {
    const { inn, name, postal_code, scan_limit_max, address, municipality, type } = value;
    // const convertedTypes = type.map((v) => parseInt(v))
    const body = {
      inn: +inn,
      organization_id: entity.id,
      name,
      postal_code: +postal_code || '',
      scan_limit_max: +scan_limit_max,
      address: address,
      municipality: municipality,
      type: Number(type),
    };

    await organizationsAPIs
      .editOrganization(body)
      .then((res) => {
        createNotification('Организация изменена');
        onUpdate();
        handleCloseEdit(res);
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Ошибка при изменении организации');
      });
  };

  return (
    <Dialog
      open={dialog.visibility}
      className="tw-rounded-lg"
      sx={{
        '& .MuiPaper-root': {
          width: mobile ? '90%' : '440px',
          margin: mobile ? '0px' : '32px',
        },
      }}
    >
      <div className="tw-rounded-lg tw-bg-white tw-flex tw-flex-col tw-p-6">
        <p className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-6">Редактирование организации</p>
        <Form
          mutators={{
            ...arrayMutators,
          }}
          onSubmit={(value) => {
            handleEditOrganization(value);
          }}
          validate={validate}
          initialValues={{
            name: entity.name,
            inn: entity.inn === 'None' ? '' : entity.inn,
            address: entity?.address || '',
            postal_code: entity?.postal_code || '',
            scan_limit_max: entity?.scan_limit_max || '',
            municipality: entity?.municipality || '',
            type: String(entity?.type || ''),
          }}
          render={({ handleSubmit, submitting, form, invalid, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-flex tw-flex-col tw-w-full tw-gap-6">
                <div>
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium tw-mb-[10px]">ИНН*</p>
                  <Input placeholder={'ИНН*'} name="inn" type="number" required />
                </div>
                <div>
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium tw-mb-[10px]">
                    Название организации*
                  </p>
                  <Input placeholder={'Название*'} name="name" type="text" required />
                </div>
                <div>
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium tw-mb-[10px]">Адрес</p>
                  <Input placeholder={'Адрес*'} name="address" type="text" />
                </div>
                <div>
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium tw-mb-[10px]">Индекс</p>
                  <Input placeholder={'Почтовый индекс*'} name="postal_code" type="number" />
                </div>

                <div>
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium tw-mb-[10px]">
                    Количество использованных лимитов (за месяц)
                  </p>
                  <div className="tw-w-full tw-py-[14px] tw-px-4 tw-border tw-border-[#CCCCCC] tw-rounded-lg">
                    <p className="tw-text-[#191919] tw-text-sm">{organization?.count_scan_in_month}</p>
                  </div>
                </div>
                <div>
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium tw-mb-[10px]">
                    Лимит (месяц)
                  </p>
                  <Input
                    className="tw-flex tw-flex-col tw-gap-1"
                    placeholder={'Лимит (месяц)*'}
                    name="scan_limit_max"
                    type="number"
                    required
                    inlineError
                    errorStyle="tw-bottom-0"
                  />
                </div>
                <div>
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium tw-mb-[10px]">Район</p>
                  <Input name="municipality" type="text" placeholder="Район компании" required />
                </div>
                <div className="tw-flex tw-flex-col tw-items-start tw-gap-[10px]">
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Признак</p>
                  <RadioGroup name="type" options={ORGANIZATION_TYPE_OPTIONS} />
                </div>
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-6 tw-mt-4">
                  <Button
                    variant="text"
                    className="tw-w-fit tw-p-0 tw-text-[#666666] tw-text-sm hover:tw-bg-transparent tw-leading-[18px] hover:tw-text-[#7f7f7f]"
                    disabled={submitting}
                    onClick={() => handleCloseEdit()}
                  >
                    Отменить
                  </Button>
                  <Button
                    className="tw-w-fit tw-px-4 tw-py-3 tw-text-white tw-text-sm tw-leading-[18px]"
                    disabled={invalid || pristine || submitting}
                    type="submit"
                  >
                    Изменить
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </Dialog>
  );
};
