import { useSelector } from 'react-redux';
import { CrudTable } from '../../../components/table/CrudTable';
import Button from '../../../components/button';
import { ReactComponent as EditIcon } from '../../../icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../icons/deleteIcon.svg';
import { ReactComponent as AddIcon } from '../../../icons/addIcon.svg';
import { Form } from 'react-final-form';
import { SearchInput } from '../../../components/input/search';
import { useCallback } from 'react';
import { useDialog } from '../../../providers/dialog.provider';
import { AddUser } from './modals/AddUser';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { ConfirmModal } from '../../../components/modal/ConfirmModal';
import { Pagination } from '../../../components/table/pagination';
import {
  adminAPIs,
  useAddUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
  useGetUsersQuery,
} from '../../../services/adminAPIs';
import { ReactComponent as UserIcon } from '../../../icons/userIcon.svg';
import { checkIsAdmin } from '../../../utils/common';
import { loginAs } from '../../../store/actions/auth';
import { dispatch } from '../../../store';
import * as constants from '../../../store/constants/organizations';
import { usePaginationParams } from '../../../hooks/usePaginationParams';

const roles = {
  admin: 'Админ',
  user: 'Юзер',
  super_admin: 'Суперадмин',
};

export default function Users() {
  const dialog = useDialog();
  const currentUser = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(currentUser);
  const organization_id = useSelector((state) => state.organizations?.selected?.id);

  const { page_size, page_num, query, goToPage, handleLimitChange, handleQueryChange } = usePaginationParams();

  const [createUser] = useAddUserMutation();
  const [editUser] = useEditUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const { data: users } = useGetUsersQuery({
    page_size,
    page_num,
    query,
  });

  const columns = {
    full_name: {
      label: 'ФИО',
    },
    email: {
      label: 'Email',
    },
    org: {
      label: 'Организация',
    },
    role: {
      label: 'Роль',
      renderBodyCell: (value) => {
        return <p>{roles[value.role]}</p>;
      },
    },
    // limits: {
    //   label: 'Лимит (месяц)',
    //   renderBodyCell: (value) => {
    //     return (
    //       <Button variant='text' className='tw-w-fit tw-p-0 hover:tw-bg-transparent tw-leading-[18px] tw-font-medium tw-text-sm'>
    //         {value?.count_month_limit} / {value?.max_scan_product_month || '-'}
    //       </Button>
    //     )
    //   }
    // }
  };

  const handleCreateUser = useCallback(
    async (body) => {
      try {
        await createUser(body)
          .unwrap()
          .then(() => {
            createNotification('Пользователь создан');
          });
      } catch (err) {
        console.error(err);
        createErrorNotification('Ошибка создания пользователя');
      }
    },
    [createUser]
  );

  const handleEditUser = useCallback(
    async (payload) => {
      try {
        await editUser(payload)
          .unwrap()
          .then(() => {
            createNotification('Пользователь изменён');
          });
      } catch (err) {
        console.error(err);
        createErrorNotification('Произошла ошибка при изменении пользователя');
      }
    },
    [editUser]
  );

  const handleDeleteUser = useCallback(
    async (id) => {
      try {
        await deleteUser(id);
      } catch (err) {
        console.error(err);
        createErrorNotification('Произошла ошибка при удалении пользователя');
      }
    },
    [deleteUser]
  );

  const showFormModal = useCallback(() => {
    dialog.open(
      <AddUser
        title={'Новый пользователь'}
        value={{
          full_name: '',
          email: '',
          password: '',
          role: '',
          max_scan_product_day: 300,
          max_scan_product_month: 2000,
        }}
        onSubmit={handleCreateUser}
      />
    );
  }, [dialog, handleCreateUser]);

  const showEditModal = useCallback(
    (entity) => {
      dialog.open(
        <AddUser
          title="Редактировать пользователя"
          value={{
            id: entity.id,
            full_name: entity.full_name,
            email: entity.email,
            role: roles[entity.role],
            max_scan_product_day: entity?.max_scan_product_day,
            max_scan_product_month: entity?.max_scan_product_month,
          }}
          onSubmit={handleEditUser}
        />
      );
    },
    [dialog, handleEditUser]
  );
  const showConfirmationModal = useCallback(
    (entity) => {
      dialog.open(
        <ConfirmModal
          value={entity}
          title={'Вы уверенны, что хотите удалить пользователя?'}
          onAgree={handleDeleteUser}
        />
      );
    },
    [dialog, handleDeleteUser]
  );

  const handleLoginIntoDifferentAccount = async (id) => {
    if (!isAdmin) {
      createErrorNotification('У вас недостаточно прав.');
      return;
    }
    // To be able to return to start account (for admin)
    const startedAccount = sessionStorage.getItem('c_user');
    if (organization_id) {
      dispatch({ type: constants.ORGANIZATIONS__DELETE_ORGANIZATION, id: organization_id });
    }

    await adminAPIs
      .loginAs(id)
      .then(({ token, user }) => {
        loginAs(token, user);
        if (startedAccount === null) {
          sessionStorage.setItem('c_user', currentUser.id);
        }
      })
      .catch((err) => {
        createErrorNotification('Произошла ошибка при попытке входа в аккаунт');
      });
  };

  const crudButtons = (entity) => {
    return (
      <div className="tw-flex tw-flex-row tw-gap-6">
        <Button
          variant="text"
          className="tw-w-fit tw-p-0 hover:tw-bg-inherit"
          onClick={() => handleLoginIntoDifferentAccount(entity.id)}
        >
          <UserIcon />
        </Button>
        <Button variant="text" className="tw-w-fit tw-p-0 hover:tw-bg-inherit" onClick={() => showEditModal(entity)}>
          <EditIcon />
        </Button>
        {entity.id !== currentUser.id && (
          <Button
            variant="text"
            className="tw-w-fit tw-p-0 hover:tw-bg-inherit"
            onClick={() => showConfirmationModal(entity)}
          >
            <DeleteIcon />
          </Button>
        )}
      </div>
    );
  };
  return (
    <>
      <div className="tw-flex tw-flex-col">
        <div className="tw-bg-white tw-py-[25px] tw-rounded-lg">
          <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-4">
            <Form
              onSubmit={() => {}}
              initialValues={{
                search: query,
              }}
            >
              {() => (
                <>
                  <SearchInput
                    name="search"
                    className="tw-max-w-[312px]"
                    onSearchChange={handleQueryChange}
                    placeholder="Найти пользователя"
                    type="text"
                    required
                  />
                </>
              )}
            </Form>
            <Button
              variant="text"
              className="tw-p-2 tw-w-fit tw-flex tw-flex-row tw-gap-1 tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-semibold"
              onClick={() => showFormModal()}
            >
              <AddIcon /> Новый пользователь
            </Button>
          </div>
          <CrudTable rows={users?.data || []} entityColumns={columns} crudButtons={crudButtons} />
        </div>
      </div>
      <div>
        <Pagination
          rowsPerPage={page_size}
          count={users?.count}
          page={page_num}
          onPageChange={goToPage}
          onLimitChange={handleLimitChange}
        />
      </div>
    </>
  );
}
