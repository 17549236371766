import { useCallback } from 'react';
import Button from '../../components/button';
import GeneralLayout from '../../components/general-layout';
import { Pagination } from '../../components/table/pagination';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import { ReactComponent as AddIcon } from '../../icons/addIcon.svg';
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';
import { Form } from 'react-final-form';
import classes from './categories-handbook.module.sass';
import { SearchInput } from '../../components/input/search';
import { useDialog } from '../../providers/dialog.provider';
import { FormDialog } from './components/FormDialog';
import { CrudTable } from '../../components/table/CrudTable';
import {
  useAddHandbookMutation,
  useDeleteHandbookMutation,
  useEditHandbookMutation,
  useGetHandbooksQuery,
  useLazyGetHandbookQuery,
} from '../../services';
import { ConfirmModal } from '../../components/modal/ConfirmModal';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { usePaginationParams } from '../../hooks/usePaginationParams';

export default function CategoriesHandbook() {
  const { page_num, page_size, query, handleLimitChange, goToPage, handleQueryChange } = usePaginationParams();

  const { data: handbooks, isLoading } = useGetHandbooksQuery({
    endpoint: 'categories',
    limit: page_size,
    page_num,
    query,
  });

  const [createHandbook] = useAddHandbookMutation();
  const [editHandbook] = useEditHandbookMutation();
  const [deleteHandbook] = useDeleteHandbookMutation();
  const [getHandbook] = useLazyGetHandbookQuery();

  const dialog = useDialog();

  const columns = {
    name: {
      label: 'Имя',
    },
    sub_categories: {
      label: 'Подкатегория',
      renderBodyCell: (value) => {
        return (
          <>
            {Array.isArray(value.sub_categories) ? (
              <p className="tw-line-clamp-2">{value.sub_categories.join(', ')}</p>
            ) : (
              <p>{value.sub_categories}</p>
            )}
          </>
        );
      },
    },
  };

  const createCategory = useCallback(
    async (category) => {
      try {
        await createHandbook({ endpoint: 'categories', body: category })
          .unwrap()
          .then(() => {
            createNotification('Категория создана', { type: 'success', hideProgressBar: true });
          });
      } catch (err) {
        console.error(err);
        createErrorNotification('Что-то пошло не так');
      }
    },
    [createHandbook]
  );

  const editCategory = useCallback(
    async (id, category) => {
      try {
        await editHandbook({ endpoint: 'categories', id, body: category });
        createNotification('Категория изменена', { type: 'success', hideProgressBar: true });
      } catch (err) {
        console.error(err);
        createErrorNotification('Что-то пошло не так');
      }
    },
    [editHandbook]
  );

  const showCreateCategoryForm = useCallback(() => {
    dialog.open(
      <FormDialog
        title={'Добавление категории'}
        value={{
          name: '',
          standard_size: '',
          sub_categories: '',
        }}
        onSubmit={createCategory}
      />
    );
  }, [dialog, createCategory]);

  const showEditCategoryForm = useCallback(
    async ({ id }) => {
      await getHandbook({ endpoint: 'categories', id })
        .unwrap()
        .then((category) => {
          dialog.open(<FormDialog value={category} title={'Редактирование категории'} onSubmit={editCategory} />);
        });
    },

    [dialog, editCategory, getHandbook]
  );

  const deleteSize = useCallback(
    async (id) => {
      await deleteHandbook({ endpoint: 'categories', id });
    },
    [deleteHandbook]
  );

  const askConfirmationDelete = useCallback(
    (category) => {
      dialog.open(
        <ConfirmModal
          value={category}
          title={`Вы уверены, что хотите удалить категорию ${category.name}?`}
          onAgree={deleteSize}
        />
      );
    },
    [dialog, deleteSize]
  );

  const crudButtons = (entity) => {
    return (
      <div className="tw-flex tw-flex-row tw-border-b-0">
        <Button
          variant="text"
          className="tw-p-0 tw-max-w-6 hover:tw-bg-inherit"
          onClick={() => showEditCategoryForm(entity)}
        >
          <EditIcon />
        </Button>
        <Button
          variant="text"
          className="tw-p-0 tw-max-w-6 hover:tw-bg-inherit"
          onClick={() => askConfirmationDelete(entity)}
        >
          <DeleteIcon />
        </Button>
      </div>
    );
  };

  return (
    <GeneralLayout>
      <h1 className="tw-text-[2rem] tw-font-semibold tw-leading-[2.375rem] tw-text-[#191919] tw-mb-8">
        Справочник категорий
      </h1>
      <div className="tw-bg-white tw-py-[25px] tw-px-[24px] tw-rounded-lg">
        <div className="tw-flex tw-flex-row tw-justify-between">
          <Form onSubmit={() => {}}>
            {() => (
              <SearchInput
                className={` tw-max-w-[312px] ${classes.searchInput}`}
                onSearchChange={handleQueryChange}
                name="search"
                placeholder="Найти категорию"
                type="text"
                required
                size="small"
              />
            )}
          </Form>
          <Button variant="text" className="tw-p-2 tw-w-[fit-content]" onClick={() => showCreateCategoryForm()}>
            <AddIcon className="tw-mr-[4px]" />
            Добавить категорию
          </Button>
        </div>
        {!isLoading ? (
          <CrudTable rows={handbooks?.data} entityColumns={columns} crudButtons={crudButtons} />
        ) : (
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-10">
            <div
              class="tw-inline-block tw-h-8 tw-w-8 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-[#0844B4] tw-border-r-transparent tw-align-[-0.125em] tw-text-primary tw-motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            />
          </div>
        )}
      </div>
      <Pagination
        rowsPerPage={page_size}
        count={handbooks?.count}
        page={page_num}
        onPageChange={goToPage}
        onLimitChange={handleLimitChange}
      />
    </GeneralLayout>
  );
}
