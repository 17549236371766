import { Dialog, DialogActions } from '@mui/material';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { Form, Field } from 'react-final-form';
import { useDialog } from '../../../providers/dialog.provider';
import { useCallback, useState, useEffect } from 'react';
import { createErrorNotification } from '../../../utils/notifications';
import { WarningMessage } from '../../../components/modals/WarningMessage';

const validateForm = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Обязательное поле";
  }
  return errors;
};

export const SizesFormDialog = ({ title, onSubmit, onReject, value, btnProp = "Добавить" }) => {
  const dialog = useDialog();
  const [formValue, setFormValue] = useState(value);

  useEffect(() => {
      setFormValue(value);
  }, [value]);

  const handleFormSubmit = useCallback(
    async (payload) => {
      const { id, name, length, height, width, maximum_load_weight } = payload;
      const body = {
        name: name,
        length: length !== '' ? parseInt(length, 10) : length,
        height: height !== '' ? parseInt(height, 10) : height,
        width: width !== '' ? parseInt(width, 10) : width,
        maximum_load_weight: maximum_load_weight !== '' ? parseInt(maximum_load_weight, 10) : maximum_load_weight,
        size_units: 'мм',
        weight_units: 'г',
      };
      try {
        onSubmit &&
          (!id ? await onSubmit(body).then(() => dialog.close()) : await onSubmit(id, body).then(() => dialog.close()));
      } catch (err) {
        console.error(err);
      }
    },
    [onSubmit, dialog]
  );

  const handleFormReject = useCallback(() => {
    onReject && onReject();
    dialog.close();
  }, [onReject, dialog]);


  return (
    <Dialog open={dialog.visibility}>
      <Form
        initialValues={formValue}
        onSubmit={handleFormSubmit}
        validate={validateForm}
        render={({ handleSubmit, values, submitError, submitting, form, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="tw-p-6 tw-rounded-xl">
              <p className="tw-text-base tw-font-semibold tw-text-[#191919]">{title}</p>
              <div className="tw-mt-6 tw-mb-14">
                <div className="tw-w-full tw-mb-5">
                  <p className="tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]">Имя</p>
                  <Field name="name">
                    {({input}) => (
                      <>
                        <Input {...input} />
                      </>
                    )}
                  </Field>
                </div>
                <WarningMessage/>
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-6 tw-mt-3">
                  <div>
                    <p className='tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]'>Длина (мм.)</p>
                    <Field name="length">
                      {({ input }) => (
                        <>
                          <Input {...input} type="number" />
                        </>
                      )}
                    </Field>
                  </div>
                  <div>
                    <p className='tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]'>Высота (мм.)</p>
                    <Field name="height">
                      {({ input }) => (
                        <>
                          <Input {...input} type="number" />
                        </>
                      )}
                    </Field>
                  </div>
                  <div>
                    <p className='tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]'>Ширина (мм.)
                    </p>
                    <Field name="width">
                      {({ input }) => (
                        <>
                          <Input {...input} type="number" />
                        </>
                      )}
                    </Field>
                  </div>
                  <div>
                    <p className='tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]'>Макс. вес (гр.)</p>
                    <Field name="maximum_load_weight">
                      {({ input }) => (
                        <>
                          <Input {...input} type="number" />
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <DialogActions className="tw-gap-[18px]">
                <Button
                  className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm"
                  variant="text"
                  disabled={submitting}
                  onClick={handleFormReject}
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-[91px] tw-rounded-lg tw-font-semibold tw-text-sm"
                  disabled={invalid || pristine}
                  type="submit"
                >
                  {btnProp}
                </Button>
              </DialogActions>
              {submitError && createErrorNotification(submitError)}
            </div>
          </form>
        )}
      ></Form>
    </Dialog>
  );
};
