import { request } from '../utils/request';
import { Api } from './api-client';

export const organizationsAPIs = {
  searchInOrganization: (organizationId, query = '') =>
    request(`/api/organizations/${organizationId}/search?query=${query}`, {
      method: 'GET',
    }),
  getNews: (organizationId) =>
    request(`/api/organizations/${organizationId}/news`, {
      method: 'GET',
    }),
  getProductsInSuppliers: (organizationId, supplierId) =>
    request(`/api/organizations/${organizationId}/suppliers/${supplierId}`, {
      method: 'GET',
    }),
  addSupplier: (organizationId, body) =>
    request(`/api/organizations/${organizationId}/suppliers`, {
      method: 'POST',
      body,
    }),
  addSupplierInReport: (reportId, productId, body) =>
    request(`/api/reports/${reportId}/products/${productId}/suppliers`, {
      method: 'POST',
      body,
    }),
  getOrganizations: (query) =>
    request(`/api/organizations?query=${query}`, {
      method: 'GET',
    }),
  getOrganizationsAdmin: (query) =>
    request(`/api/admin/organizations?query=${query}`, {
      method: 'GET',
    }),
  editInn: (body) =>
    request('/api/admin/accesses/edit_inn', {
      method: 'POST',
      body,
    }),
  editOrganization: (body) =>
    request(`/api/admin/organizations/update`, {
      method: 'PATCH',
      body,
    }),
  getAdminOrganization: (id) =>
    request(`/api/admin/organizations/${id}`, {
      method: 'GET',
    }),
  getUserOrganization: (id) =>
    request(`/api/organizations/${id}`, {
      method: 'GET',
    }),
  updateOrganizationsLimits: (body) =>
    request(`/api/admin/organizations/import_limits`, {
      method: 'POST',
      body,
    }),
  updateOrganizationsInn: (body) =>
    request('/api/admin/organizations/import_inn', {
      method: 'POST',
      body,
    }),
  getInfoByInn: (inn) =>
    request(`/api/admin/organizations/get_info_by_inn?inn=${inn}`, {
      method: 'GET',
    }),
  blockSource: (organization_id, body) =>
    request(`/api/organizations/${organization_id}/blocked-host`, {
      method: 'POST',
      body,
    }),
  getBlockedSourcesByOrganization: (organization_id) =>
    request(`/api/organizations/${organization_id}/blocked-hosts`, {
      method: 'GET',
    }),
  deleteSourceFromBlocked: (organization_id, body) =>
    request(`/api/organizations/${organization_id}/blocked-host`, {
      method: 'DELETE',
      body
    }),
};

export const newOrganizationsAPIs = Api.injectEndpoints({
  endpoints: (builder) => ({
    removeProjectOrganizationData: builder.mutation({
      query: ({ organization_id, body }) => ({
        url: `/api/admin/storage/delete_organization/${organization_id}`, // Исправлено: URL передается через ключ `url`
        method: 'DELETE',
        body: body,
      }),
      invalidatesTags: [{type: 'OrganizationsStorageScreenshots'}]
    }),
    removeProjectUserData: builder.mutation({
      query: ({ user_id, body }) => ({
        url: `/api/admin/storage/delete_user/${user_id}`,
        method: 'DELETE',
        body: body,
      }),
      invalidatesTags: [{type: 'UsersStorageScreenshots'}]
    }),
    getAdminOrganization: builder.query({
      query: (id) => ({
        url: `/api/admin/organizations/${id}`,
        method: 'GET'
      }),
      providesTags: ['Organization']
    })
  }),
});

export const { useRemoveProjectOrganizationDataMutation, useRemoveProjectUserDataMutation, useGetAdminOrganizationQuery } = newOrganizationsAPIs;
